import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
// import anime from 'animejs/lib/anime.es';
import { TransitionGroup, Transition } from 'react-transition-group';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.scss';
import { resize } from './store/modules/resizing';
import Home from './routes/Home';
import Header from './components/Header';
import About from './routes/About';
import CaseStudies from './routes/CaseStudies';
import Contact from './routes/Contact';
import Page404 from './routes/404';
import Services from './routes/Services';
import CaseStudy from './routes/CaseStudy';
import caseStudiesContent from './data/case-studies.json';
import homeContent from './data/home.json';
import globalContent from './data/global.json';
import GoogleAnalytics from './components/GoogleAnalytics';

const innerHeight = require('ios-inner-height');

class App extends Component {
  constructor() {
    super();
    this.state = { };
    this.analytics = React.createRef();
    this.router = React.createRef();
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  homePaths() {
    var paths = ['/'];

    homeContent['activities'].map((activity, x) => {
      paths.push('/' + activity.urlSlug);
    });

    return paths;
  }

  handleResize = () => {
    this.props.resize(window.innerWidth, innerHeight());
  };

  setCurrentComponent = comp => {
    if (this.currentComponent) this.exitingComponent = this.currentComponent;
    this.currentComponent = comp;
    this.nextID = this.currentComponent.compName;
    if (this.exitingComponent) {
      this.currentComponent.ctn.current.classList.add('enter');

      if (this.currentComponent.name === 'home') {
        document.body.classList.add('home');
      } else {
        document.body.classList.remove('home');
      }

      this.currentComponent.isAnimating = true;
      this.currentComponent.ctn.current.style.zIndex = '1';
      this.exitingComponent.isAnimating = true;
      this.exitingComponent.ctn.current.style.zIndex = '0';
      if (this.exitingComponent.webglCtn) {
        this.exitingComponent.webglCtn.style.zIndex = '-1';
      }
    }
  };

  onHelmetChange = ({ metaTags }) => {
    if (this.router.current !== null) {
      if (this.analytics.current !== null) {
        this.analytics.current.updateAnalytics(this.router.current.history.location);
      }
    }
  };

  onHomeLoaderReady = () => {
    this.homeAlreadyLoaded = true;
    this.handleResize();
  }

  getRouter() {
    return (
      <Router ref={this.router}>
        <div>
          <Route
            render={({ location }) => {
              this.previousLoc = location.pathname;
              return (
                <TransitionGroup className="Routes">
                  <Transition
                    onEnter={(el, isAppearing) => {
                      if (isAppearing) {
                        if (this.currentComponent.name === 'home') {
                          document.body.classList.add('home');
                        } else {
                          document.body.classList.remove('home');
                        }
                        this.currentComponent.animateIn(0);
                      }
                    }}
                    appear
                    unmountOnExit={true}
                    mountOnEnter={true}
                    timeout={1650}
                    key={location.key}
                    onExit={el => {}}
                    onExiting={el => {
                      this.currentComponent.animateIn(150).then(() => {
                        this.currentComponent.ctn.current.style.zIndex = '0';
                        window.scrollTo(0, 0);
                        this.currentComponent.ctn.current.classList.remove('enter');

                        this.currentComponent.isAnimating = false;
                        this.exitingComponent.isAnimating = false;
                        if (this.exitingComponent.ctn && this.exitingComponent.ctn.current) {
                          this.exitingComponent.ctn.current.style.display = 'none';
                        }

                        if (this.exitingComponent.name === 'home' && this.currentComponent.name !== 'home') {
                          document.body.classList.remove('home');
                        }
                      });
                    }}
                    onExited={el => {}}
                  >
                    <Switch location={location}>
                      <Route
                        exact
                        path="/godincms"
                      >
                        <Redirect to='/godincms/index.html' />
                      </Route>
                      <Route
                        exact
                        path={this.homePaths()}
                        render={props => <Home onSetCurrentComponent={this.setCurrentComponent} {...props} onLoaderReady={this.onHomeLoaderReady} alreadyLoaded={this.homeAlreadyLoaded} />}
                      />
                      <Route
                        exact
                        path="/about"
                        render={props => <About onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/case-studies"
                        render={props => <CaseStudies onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/services"
                        render={props => <Services onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/contact"
                        render={props => <Contact onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      {caseStudiesContent['case-studies'].map((caseStudy, i) => (
                        <Route
                          exact
                          key={i}
                          path={`/case-studies/${caseStudy.slug}`}
                          render={props => (
                            <CaseStudy
                              id={i}
                              content={caseStudy}
                              onSetCurrentComponent={this.setCurrentComponent}
                              {...props}
                            />
                          )}
                        />
                      ))}
                      <Route render={props => <Page404 onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                    </Switch>
                  </Transition>
                </TransitionGroup>
              );
            }}
          />
          <Header />
          <GoogleAnalytics ref={this.analytics} />
        </div>
      </Router>
    );
  }

  render() {
    return (
      <div className="App">
        <Helmet onChangeClientState={this.onHelmetChange}>
          <title>{globalContent.metaTitle}</title>
        </Helmet>
        {this.getRouter()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resize: (width, height) => dispatch(resize(width, height))
});

const mapStateToProps = state => {
  return {
    viewPortWidth: state.resizing.width,
    viewPortHeight: state.resizing.height
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
