import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import anime from 'animejs/lib/anime.es';
import ReactHtmlParser from 'react-html-parser';
import './style.scss';
import homeContent from '../../data/home.json';
import checkProps from '../../util/check-props';
import BasicButton from '../BasicButton';
import { changeFrame } from '../../store/modules/home';

var showdown = require('showdown');

class HomeSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { appear: false, properHeight: 0 };
    this.ctn = React.createRef();
    this.left = React.createRef();
    this.mk = new showdown.Converter();
    // this.title = React.createRef();
    // this.par = React.createRef();
  }

  componentDidMount() {
    anime({
      targets: this.left.current.children,
      opacity: 0,
      duration: 0
    });
  }

  componentWillReceiveProps(nextProps) {}

  animateIn = () => {
    this.ctn.current.style.display = 'flex';
    if(this.props.viewPortWidth < 769) this.ctn.current.style.display = 'block';

    this.getProperHeight();
    return anime({
      targets: this.left.current.children,
      opacity: [0, 1],
      delay: anime.stagger(200),
      duration: 1000,
      easing: 'easeOutSine'
    }).finished;
  };

  animateOut = () => {
    return anime({
      targets: this.left.current.children,
      opacity: [1, 0],
      delay: anime.stagger(100),
      duration: 500,
      easing: 'easeOutSine',
      complete: () => {this.ctn.current.style.display = 'none';}
    }).finished;
  };

  backToHome = () => {
    this.props.changeFrame(0);
  };

  getProperHeight() {
    let nb = this.props.viewPortHeight;
    if(this.props.viewPortWidth < 769) {
      //this.ctn.current.firstChild.getBoundingClientRect().height +
      nb = this.props.viewPortHeight * 0.5;
    }

    this.setState({ properHeight: nb });
  }

  render() {
    let sectionStyle = { minHeight: this.state.properHeight + 'px' };
    if(this.props.viewPortWidth < 769) sectionStyle = { paddingBottom: 0 + 'px' };
    return (
      <div
        className="HomeSection"
        ref={this.ctn}
        style={sectionStyle}
        id={`home${this.props.id}`}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-7 col-lg-6 col-xl-5 left text-white" ref={this.left}>
              {this.props.id !== 0 && (
                <button onClick={this.backToHome} className="back-btn">
                  Back to the hub
                </button>
              )}
              {/* homeContent.activities[this.props.id].surTitle.length > 0 && (
                <h2>{homeContent.activities[this.props.id].surTitle}</h2>
              ) */}
              <h1>{homeContent.activities[this.props.id].title}</h1>
              {homeContent.activities[this.props.id].content.length > 0 && (
                <div className="paragraph">
                  {homeContent.activities[this.props.id].mobileContent !== '' && (
                    <div>
                      <div className="paragraph__full d-md-block">{ReactHtmlParser(this.mk.makeHtml(homeContent.activities[this.props.id].content))}</div>
                      <div className="paragraph__mobile d-md-none">{ReactHtmlParser(this.mk.makeHtml(homeContent.activities[this.props.id].mobileContent))}</div>
                    </div>
                  )}

                  {homeContent.activities[this.props.id].mobileContent === '' && (
                    <div className="d-md-block">
                      {ReactHtmlParser(this.mk.makeHtml(homeContent.activities[this.props.id].content))}
                    </div>
                  )}
                </div>
              )}
              <div className="button button--mobile d-md-block">
                <BasicButton
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.props.id === 0) {
                      this.props.changeFrame(1);
                    } else {
                      this.props.history.push(homeContent.activities[this.props.id].buttonURL);
                    }
                  }}

                  url={homeContent.activities[this.props.id].buttonURL}
                  className="btn-lg"
                >
                  {homeContent.activities[this.props.id].buttonLabel}
                </BasicButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomeSection.propTypes = checkProps({
  scrollPosition: PropTypes.number,
  viewPortHeight: PropTypes.number,
  viewPortWidth: PropTypes.number,
  changeFrame: PropTypes.func,
  dispatch: PropTypes.func,
  id: PropTypes.number
});

HomeSection.defaultProps = {};

const mapDispatchToProps = dispatch => ({
  changeFrame: frame => dispatch(changeFrame(frame))
});

const mapStateToProps = state => {
  return {
    viewPortHeight: state.resizing.height,
    viewPortWidth: state.resizing.width,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(HomeSection);
