import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import './style.scss';

import checkProps from '../../util/check-props';

import content from '../../data/about.json';
import ScrollComponent from '../../components/ScrollComponent';
import ContactBlock from '../../components/ContactBlock';
import PageMeta from '../../components/PageMeta';

var showdown = require('showdown');

class About extends ScrollComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.name = 'about';
    this.ctn = React.createRef();
    this.mk = new showdown.Converter();
  }

  componentDidMount() {
    this.props.onSetCurrentComponent(this);
    super.componentDidMount();
  }

  componentWillReceiveProps(nextProps) {}

  openProject = slug => {
    this.props.history.push('/case-studies/' + slug);
  };

  getLogos() {
    return content.clientLogoList.map((client, i) => (
      <li key={i} className="scroll-appear d-flex align-items-center">
        <img src={client.logoImage} alt={client.clientName} />
      </li>
    ));
  }

  render() {
    return (
      <div className="About" ref={this.ctn}>
        <PageMeta title={content.title} />
        <div className="regular-page pt-sm">
          <div className="container">
            <div className="crumbs content-wrapper hundred-percent height-auto white pb-5">
              &nbsp;
            </div>
          </div>
          <section className="content-section container pt-0">
            <div className="row align-items-start">
              <div className="col-12 col-md-5 col-lg-4 pt-2">
                <h1 className="">{content.title}</h1>
              </div>
              <div className="col-12 col-md-7 col-lg-8">
                {ReactHtmlParser(this.mk.makeHtml(content.overview))}
              </div>
            </div>
          </section>
          <section className="content-section container pt-0">
            <div className="row align-items-start mb-5 pb-5">
              <div className="col-12 col-md-5 col-lg-4">
                <h2 className="scroll-appear h1">{content.teamTitle}</h2>
              </div>
              <div className="col-12 col-md-7 col-lg-8">
                <p className="scroll-appear lead strong">{content.teamContent}</p>
              </div>
            </div>
            {content.teamList.map((t, i) => (
              <div className="row team mb-5 mb-md-4 scroll-appear" key={i}>
                <div className="col-12 col-md-5 col-lg-4">
                  <img src={t.image} className="scroll-appear mw-100" width="250" alt={t.name} />
                </div>
                <div className="col-12 col-md-7 col-lg-8">
                  <div className="mb-3 strong">{t.name}, {t.jobTitle}</div>
                  <p>{t.personQuote}</p>
                </div>
              </div>
            ))}
          </section>
          <section className="bg-primary text-white">
            <div className="content-section container">
              <div className="row align-items-end mb-5">
                <div className="col-12 col-md-5 col-lg-4">
                  <h2 className="h1">Our Clients</h2>
                </div>
                <div className="col-12 col-md-7 col-lg-8">
                  <p className="scroll-appear lead strong">{content.clientOverview}</p>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-12 col-md-7-x col-lg-8 ml-4">
                  <div className="logo-grid">
                    <ul className="d-flex flex-director-row flex-wrap mb-0 justify-content-center">{this.getLogos()}</ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="content-section container">
            <div className="row align-items-end mb-5">
              <div className="col-12 col-md-5 col-lg-4">
                <h2 className="scroll-appear h1">Testimonials</h2>
              </div>
              <div className="col-12 col-md-7 col-lg-8">
                <p className="scroll-appear lead strong">{content.clientTitle}</p>
              </div>
            </div>
            {content.testimonialsList.map((testimonial, i) => (
              <div className="row testimonial mb-5 pt-3 align-items-center scroll-appear" key={i}>
                <div className="col-12 col-md-5 col-lg-4 testimonial__person">
                  <p>
                    <span className="testimonial__author">{testimonial.author}</span><br/>
                    <span className="testimonial__jobTitle">{testimonial.jobTitle}</span>,<br/>
                    <span className="testimonial__company">{testimonial.company}</span>
                  </p>
                </div>
                <div className="col-md-7 col-lg-6 mb-4 testimonial__quote">
                  &ldquo;{testimonial.quote}&rdquo;
                </div>
              </div>
            ))}
          </section>
          <ContactBlock />
        </div>
      </div>
    );
  }
}

About.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func,
  dispatch: PropTypes.func,
  viewPortHeight: PropTypes.number
});

About.defaultProps = {};

const mapStateToProps = state => {
  return {
    viewPortHeight: state.resizing.height
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(About));
