import { ASSETS_PATH, CUBE_PATH, MOBILE_CUBE_PATH } from './sitesettings';
import homeContent from './home.json';

let loadingAssets = {
  mobile: [{
    assetType: 'gltf',
    url: ASSETS_PATH + MOBILE_CUBE_PATH
  },
  {
    assetType: 'texture',
    url: ASSETS_PATH + 'images/cube-shadow.png'
  },
  {
    assetType: 'texture',
    url: ASSETS_PATH + 'images/bg-gradient.jpg'
  },
  {
    assetType: 'texture',
    url: ASSETS_PATH + 'images/cube/plus.png'
  },
  {
    assetType: 'texture',
    url: ASSETS_PATH + 'images/cube/plus-hover.png'
  }],
  others: [{
    assetType: 'gltf',
    url: ASSETS_PATH + CUBE_PATH
  },
  {
    assetType: 'texture',
    url: ASSETS_PATH + 'images/cube-shadow.png'
  },
  {
    assetType: 'texture',
    url: ASSETS_PATH + 'images/bg-gradient.jpg'
  },
  {
    assetType: 'texture',
    url: ASSETS_PATH + 'images/cube/plus.png'
  },
  {
    assetType: 'texture',
    url: ASSETS_PATH + 'images/cube/plus-hover.png'
  }]
};
const cubeButtons = homeContent.activities.slice();

//Removing first element as it's not used
cubeButtons.shift();
loadingAssets.others = loadingAssets.others.concat(cubeButtons.map((c, i) => {
  return {
    assetType: 'texture',
    url: c.cubeButton
  };
}));
loadingAssets.mobile = loadingAssets.mobile.concat(cubeButtons.map((c, i) => {
  return {
    assetType: 'texture',
    url: c.cubeButton
  };
}));

export default loadingAssets;
