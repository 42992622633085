import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Helmet from 'react-helmet';
import globalContent from '../../data/global.json';

import checkProps from '../../util/check-props';

export default class PageMeta extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <Helmet>
        <title>
          {globalContent.metaTitle} | {this.props.title}
        </title>
      </Helmet>
    );
  }
}

PageMeta.propTypes = checkProps({
  title: PropTypes.string
});

PageMeta.defaultProps = {};
