import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import anime from 'animejs/lib/anime.es';
import { throttle } from 'throttle-debounce';
import './style.scss';

import checkProps from '../../util/check-props';
import HomeSection from '../../components/HomeSection';
import Loader from '../../components/Loader';
import assetsLoading from '../../data/assetsLoading';
// import NavCircle from '../../components/NavCircle';
import homeContent from '../../data/home.json';
// import HomeNav from '../../components/HomeNav';
import { changeFrame } from '../../store/modules/home';
import WebglBackground from '../../components/WebglBackground';

import { Lethargy } from 'lethargy';

const MobileDetect = require('mobile-detect');

class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.name = 'home';
    this.md = new MobileDetect(window.navigator.userAgent);
    this.state = { perc: 0, percNoLerp: 0, globPerc: 0, introPerc: 0, scrollPosition: 0, currSection: 0, loadingReady: false };
    // this.addVert = 0;
    this.moveVert = 0;
    this.ctn = React.createRef();
    this.ctnSections = React.createRef();
    // this.homeBG = React.createRef();
    this.webgl = React.createRef();
    this.ctnSectionsReact = [];
  }

  componentDidMount() {
    this.checkMounted = true;
    this.props.onSetCurrentComponent(this);
    this.lethargy = new Lethargy();
    anime({
      targets: this.ctn.current,
      width: 0,
      duration: 0
    });

    if(this.props.alreadyLoaded) {
      this.allLoaded();
    }
  }

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate(prevProps) {
    if (prevProps.currFrame !== this.props.currFrame) {
      this.animateOutSection(prevProps.currFrame).then(() => this.animateInSection(this.props.currFrame));
    }

    if (prevProps.menuIsOpen !== this.props.menuIsOpen) {
      if (this.props.menuIsOpen) {
        cancelAnimationFrame(this.aF);
        this.aF = -1;
      } else if (this.aF === -1) {
        this.update();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getScrollValue);
    window.removeEventListener('keydown', this.pressArrows);
    window.removeEventListener('DOMMouseScroll', throttle(30, this.mouseWheelEvent));
    window.removeEventListener('mousewheel', throttle(30, this.mouseWheelEvent));
    document.body.removeEventListener('touchmove', throttle(30, this.touchMoveEvent), { passive: false });
    document.body.removeEventListener('touchstart', this.onTouchStart, { passive: false });
    this.checkMounted = false;
    cancelAnimationFrame(this.aF);
  }

  onLoadingReady = () => {
    this.setState({ loadingReady: true }, () => {
      this.props.onLoaderReady();
      this.allLoaded();
    });
  };

  allLoaded() {
      this.webglCtn = this.webgl.current.oglCanvas.current.parentNode;
      this.allScreens = Array.from(this.ctnSections.current.children);
      this.aF = -1;
      this.mWheelTO = -1;
      this.mWheelPrevent = false;
      this.perc = this.globPerc = this.introPerc = this.scrollPosition = 0;
      this.transferToSection = false;
      this.currentScrollW = 0;

      this.isSmall = window.innerWidth <= 1024;

      const firstSection = Array.from(this.ctn.current.querySelectorAll('.HomeSection'))[0].querySelector('.left')
        .childNodes;
      anime({
        targets: firstSection,
        duration: 1000,
        delay: anime.stagger(300),
        opacity: [0, 1],
        easing: 'easeOutSine'
      });

      this.update();

      this.animateInSection(0);
      this.props.changeFrame(0);
  }

  animateIn(delay = 0) {
    // this.ctn.current.style.overflow = 'hidden';
    // this.ctn.current.firstChild.style.width = '100vw';
    // this.webglCtn.style.overflow = 'hidden';
    // this.webglCtn.style.width = '0';, this.webglCtn
    // console.log('animate in');

    return anime({
      targets: [this.ctn.current],
      width: '100vw',
      // left: '0',
      delay,
      duration: 1500,
      easing: 'easeOutExpo'
    }).finished.then(() => {
      // this.ctn.current.style.overflow = 'visible';
      // this.webglCtn.style.overflow = 'visible';
      // this.ctn.current.firstChild.style.width = 'auto';
      // this.ctn.current.firstChild.style.right = 'auto';
    });
  }

  animateOut() {
    return anime({
      targets: this.ctn.current,
      duration: 1,
      easing: 'easeInExpo'
    }).finished;
  }

  animateOutSection = id => {
    return this.ctnSectionsReact[id].current.animateOut();
  };

  animateInSection = id => {
    return this.ctnSectionsReact[id].current.animateIn();
  };

  animateMouseOut(id) {
    // this.homeBG.current.out(id);
  }

  animateOver(id) {
    // const diff = id - this.props.currFrame;
    // this.homeBG.current.over(id, this.props.currFrame, diff);
  }

  update = () => {
    this.aF = requestAnimationFrame(this.update);

    if (this.webgl && this.webgl.current) {
      this.webgl.current.render3D(this.isAnimating);
    }
  };

  getSections() {
    return homeContent.activities.map((c, i) => {
      this.ctnSectionsReact[i] = React.createRef();
      return <HomeSection key={i} id={i} ref={this.ctnSectionsReact[i]} history={this.props.history} />;
    });
  }

  renderHome() {
    return (<Fragment>
          <WebglBackground ref={this.webgl} />
          <div ref={this.ctnSections} className="allSections">
            {this.getSections()}
          </div>
        </Fragment>);
  }

  render() {
    return (
      <div className="Home" ref={this.ctn}>
        <div className="ctn">
          {(this.props.alreadyLoaded || this.state.loadingReady) && this.renderHome()}
          {!this.props.alreadyLoaded && !this.state.loadingReady && <Loader onLoadingReady={this.onLoadingReady} toLoad={this.md.mobile() !== null ? assetsLoading.mobile : assetsLoading.others} />}
      </div>
    </div>
    );
  }
}

Home.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func,
  onLoaderReady: PropTypes.func,
  changeFrame: PropTypes.func,
  currRoll: PropTypes.number,
  currFrame: PropTypes.number,
  menuIsOpen: PropTypes.bool,
  alreadyLoaded: PropTypes.bool,
  viewPortHeight: PropTypes.number,
  initialFrame: PropTypes.number,
});

Home.defaultProps = {
  initialFrame: 0,
};

const mapDispatchToProps = dispatch => ({
  changeFrame: frame => dispatch(changeFrame(frame))
});

const mapStateToProps = state => {
  return {
    menuIsOpen: state.menu.menuIsOpen,
    viewPortHeight: state.resizing.height,
    currRoll: state.home.currRoll,
    currFrame: state.home.currFrame
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
  )(Home)
);
