import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
// import ReactHtmlParser from 'react-html-parser';
import './style.scss';

import checkProps from '../../util/check-props';

import ScrollComponent from '../../components/ScrollComponent';
import PageMeta from '../../components/PageMeta';

var showdown = require('showdown');

class Page404 extends ScrollComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.name = '404';
    this.ctn = React.createRef();
    this.mk = new showdown.Converter();
  }

  componentDidMount() {
    this.props.onSetCurrentComponent(this);
    super.componentDidMount();
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <div className="Page404" ref={this.ctn}>
        <PageMeta title="404: Page Not Found" />
        <div className="regular-page">
          <div className="container text-center">
            <div className="row">
              <div className="col-12 col-md-8 offset-md-2">
                <h1>Whoopsie!</h1>
                <p className="lead">The page you are looking for no longer exists. Head <NavLink to="/">back home</NavLink> and let's try that again.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Page404.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func,
  dispatch: PropTypes.func,
  viewPortHeight: PropTypes.number
});

Page404.defaultProps = {};

const mapStateToProps = state => {
  return {
    viewPortHeight: state.resizing.height
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(Page404));
