import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import anime from 'animejs/lib/anime.es';

import checkProps from '../../util/check-props';

export default class BasicButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.ctn = React.createRef();
    this.type = this.props.type !== '' ? this.props.type : 'button';
  }

  componentDidMount() {
    this.color = this.props.className.indexOf('black') > -1 ? 'black' : 'white';

    anime({
      targets: this.ctn.current,
      boxShadow: `0px 0px 0px 1px ${this.color} inset`,
      // borderWidth: 2,
      // margin: 2,
      duration: 0
    });
  }

  componentWillReceiveProps(nextProps) {}

  over = event => {
    anime.remove(this.ctn.current);
    anime({
      targets: this.ctn.current,
      boxShadow: `0px 0px 0px 4px ${this.color} inset`,
      // borderWidth: 4,
      // margin: 0,
      duration: 250,
      easing: 'easeOutSine'
    });
  };

  out = event => {
    anime.remove(this.ctn.current);
    anime({
      targets: this.ctn.current,
      boxShadow: `0px 0px 0px 2px ${this.color} inset`,
      // borderWidth: 2,
      // margin: 2,
      duration: 250,
      easing: 'easeOutSine'
    });
  };

  render() {
    return (
      <a
        href={this.props.url}
        ref={this.ctn}
        className={`BasicButton ${this.props.className}`}
        onMouseOver={this.over}
        onMouseOut={this.out}
        onClick={this.props.onClick}
        type="submit"
      >
        <span>{this.props.children}</span>
      </a>
    );
  }
}

BasicButton.propTypes = checkProps({
  onClick: PropTypes.func,
  className: PropTypes.string,
  url: PropTypes.string
});

BasicButton.defaultProps = {
  className: '',
  url: ''
};
