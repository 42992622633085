import { ROLL_HOME, OUT_HOME, CHANGE_FRAME_HOME } from '../keys';
import homeContent from '../../data/home.json';

const defaultState = {
  currRoll: -1,
  currFrame: 0
};

export default function homeReducer(state = defaultState, action) {
  switch (action.type) {
    case ROLL_HOME:
      return {
        ...state,
        currRoll: action.frame
      };
    case OUT_HOME:
      return {
        ...state,
        currRoll: -1
      };
    case CHANGE_FRAME_HOME:
      return {
        ...state,
        currFrame: action.frame
      };
    default:
      return state;
  }
}

export function rollover(frame) {
  return { type: ROLL_HOME, frame };
}

export function rollout() {
  return { type: OUT_HOME };
}

export function changeFrame(frame) {
  //When the frame is changed, update the page URL.
  var homePath = '';

  homeContent['activities'].map((activity, x) => {
    if (frame === x) {
      homePath = '/' + activity.urlSlug;
      return true;
    }
  });

  window.history.pushState("", "", homePath);

  return { type: CHANGE_FRAME_HOME, frame };
}
