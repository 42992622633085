import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es';
import lerp from 'lerp';
import smoothstep from 'smoothstep';
import './style.scss';
import content from '../../data/global.json';
import GLTFLoader from 'three-gltf-loader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

import checkProps from '../../util/check-props';
const load = require('load-asset');

class Loader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loadingReady: false, actualProgress: 0, loaderWidth: 0 };
    this.main = React.createRef();
    this.loaderEffect = React.createRef();
    this.GLTFProgress = 0;
    this.currentProgress = 0;
    this.actualProgress = 0;
  }

  async componentDidMount() {
    window.preload = window.preload || {};
    document.body.classList.add('loading');
    this.progress = 0;
    this.currentProgress = 0;
    this.raf = -1;
    this.animateIn();
    window.addEventListener('resize', this.resize);
    this.resize();
    await this.loadFiles();
  }

  componentWillUnmount() {
    document.body.classList.remove('loading');
    cancelAnimationFrame(this.raf);
    window.removeEventListener('resize', this.resize);
  }

  animateIn() {
    anime({
      targets: this.main.current,
      opacity: [0, 1],
      translateY: [20, 0],
      duration: 1, //2000
      easing: 'easeOutSine'
    }).finished.then(() => this.update());
  }

  animateOut() {
    anime({
      targets: this.main.current,
      opacity: [1, 0],
      duration: 1, //500
      easing: 'easeInSine'
    }).finished.then(() => this.props.onLoadingReady());
  }

  resize = event => {
    const w = this.loaderEffect.current.getBoundingClientRect().width;
    this.setState({ loaderWidth: w });
  };

  loadFiles() {
    const textures = this.props.toLoad.filter(a => a.assetType === 'texture');
    const gltfs = this.props.toLoad.filter(a => a.assetType === 'gltf');
    return Promise.all([
      load.all(textures, progress => {
        window.preload[progress.target.url] = {
          ...progress.target,
          asset: progress.value
        };
        this.currentProgress += (1 / progress.total) * 0.25;
      }),
      Promise.all(
        gltfs.map(gltf =>
          this.loadGLTF(gltf).then(() => {
            return Promise.resolve();
          })
        )
      )
    ]);
  }

  componentWillReceiveProps(nextProps) {}

  loadGLTF(gltf) {
    // let isLocal = false;
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/assets/js/libs/draco/');
    loader.setDRACOLoader(dracoLoader);
    return new Promise(resolve =>
      loader.load(
        gltf.url,
        glTF => {
          window.preload[gltf.url] = glTF;
          this.GLTFProgress = 0.75;
          resolve();
        },
        // called while loading is progressing
        function(xhr) {
          if (xhr.total === 0) {
            // isLocal = true;
          } else {
            // isLocal = false;
            this.GLTFProgress = (xhr.loaded / xhr.total) * 0.75;
          }
          //if(process.env.NODE_ENV !== 'development') this.currentProgress += xhr.loaded / xhr.total * 0.75;
        },
        // called when loading has errors
        function(error) {
          console.log('An error happened' + error);
        }
      )
    );
    /* const glTFLoader = new MinimalGLTFLoader.glTFLoader();
    return new Promise(resolve =>
      glTFLoader.loadGLTF(gltf.url, glTF => {
        window.preload[gltf.url] = glTF;
        resolve();
      })
    ); */
  }

  update = () => {
    this.actualProgress = lerp(this.actualProgress, this.currentProgress + this.GLTFProgress, 0.01); //0.008
    this.setState({ actualProgress: this.actualProgress });

    const phrases = Array.from(this.loaderEffect.current.childNodes);
    const p = this.state.actualProgress * phrases.length;
    for (let i = 0; i < phrases.length; i++) {
      phrases[i].style.backgroundSize = smoothstep(i, i + 1, p) * 100 + '% 100%';
    }

    if (Math.round(this.state.actualProgress * 10) / 10 === 1 && !this.startOut) {
      this.animateOut();
      this.startOut = true;
    }
    this.raf = requestAnimationFrame(this.update);
  };

  getLoaderLines() {
    return content.loaderLines.map((l, i) => (
      <div className="loader-line" key={i}>
        {l}
      </div>
    ));
  }

  getLoaderSpanLines() {
    return content.loaderLines.map((l, i) => (
      <div className="loader-line" key={i}>
        {l}
      </div>
    ));
  }

  render() {
    return (
      <div ref={this.main} className="Loader" style={{ height: this.props.viewPortHeight + 'px' }}>
        <div className="loader-text">
          <div className="loader-text-overlay" ref={this.loaderEffect}>
            {this.getLoaderLines()}
          </div>

          {this.getLoaderSpanLines()}
        </div>
        {/* <div className="header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>
                  <img className="default" src="/assets/images/godin-logo.png" alt="Logo Godin" />
                </h1>
              </div>
            </div>
          </div>
        </div> */}
        <div className="cubeloader">
          <div>
            <img src="/assets/images/loader-cube.png" alt="Loading Cube" width="40" />
          </div>
          <div>Loading...</div>
        </div>
      </div>
    );
  }
}

Loader.propTypes = checkProps({
  toLoad: PropTypes.array,
  viewPortHeight: PropTypes.number,
  onLoadingReady: PropTypes.func,
  dispatch: PropTypes.func
});

Loader.defaultProps = {
  toLoad: []
};

const mapStateToProps = state => {
  return {
    viewPortHeight: state.resizing.height
  };
};

export default connect(mapStateToProps, null)(Loader);
