import React from 'react';
import './style.scss';

import checkProps from '../../util/check-props';
import globalContent from '../../data/global.json';
import { NavLink } from 'react-router-dom';

export default class ContactBlock extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <section className="content-section bg-primary">
        <div className="container">
          <div className="row scroll-appear">
            <div className="col-12 col-md-6 text-center text-md-left mb-4 mb-md-0">
            <NavLink to="/contact" className="text-white link-underline">{globalContent.helpBlockTitle}</NavLink>
            </div>
            <div className="col-12 col-md-6 text-center text-md-right">
              &copy; Godin Productions {(new Date().getFullYear())}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ContactBlock.propTypes = checkProps({});

ContactBlock.defaultProps = {};
