import { Object3D, Mesh } from 'three';
import BufferGeometryUtils from '../utils/BufferGeometryUtils';
import { ASSETS_PATH, CUBE_PATH, MOBILE_CUBE_PATH } from '../../../../data/sitesettings';

const MobileDetect = require('mobile-detect');

export default class CubeLoader extends Object3D {
  constructor(opts = {}) {
    super();
    this.md = new MobileDetect(window.navigator.userAgent);
    const glTF = window.preload[ASSETS_PATH + (this.md.mobile() !== null ? MOBILE_CUBE_PATH : CUBE_PATH)];
    // console.log(glTF);
    const tempObj = glTF.scenes[0].clone();
    this.add(tempObj);
    this.matPool = [];
    this.sortMeshes = [];
    this.getSortMeshes(tempObj);

    this.rootObject = new Object3D();
    this.add(this.rootObject);

    this.mergeGeometries();
    this.isCleanedUp = false;
  }

  getSortMeshes(c) {
    for (let i = 0; i < c.children.length; i++) {
      const o = c.children[i];
      if (o.isMesh) {
        this.addToRightGroup(o);
      } else if (o.isObject3D && o.children.length > 0) {
        this.getSortMeshes(o);
      }
    }
  }

  addToRightGroup(m) {
    for (let i = 0; i < this.sortMeshes.length; i++) {
      if (this.sortMeshes[i].length && this.sortMeshes[i].length > 0) {
        if (this.sortMeshes[i][0].material.name === m.material.name) {
          this.sortMeshes[i].push(m);
          return;
        }
      }
    }
    this.sortMeshes.push([m]);
  }

  mergeGeometries() {
    for (let i = 0; i < this.sortMeshes.length; i++) {
      const geometries = [];
      const material = this.sortMeshes[i][0].material.clone();
      for (let j = 0; j < this.sortMeshes[i].length; j++) {
        const tempGeom = this.sortMeshes[i][j].geometry.clone();
        this.sortMeshes[i][j].updateMatrix();
        tempGeom.applyMatrix(this.sortMeshes[i][j].matrix);
        geometries.push(tempGeom);
        this.sortMeshes[i][j].parent.remove(this.sortMeshes[i][j]);
      }
      const geom = BufferGeometryUtils.mergeBufferGeometries(geometries);
      const mesh = new Mesh(geom, material);
      mesh.name = 'new-mesh' + i;
      this.rootObject.add(mesh);
    }
  }

  cleanUp() {
    const rootObject = this.children[0].children[0];
    rootObject.updateMatrix();
    this.rootObject.position.copy(rootObject.position);
    this.rootObject.scale.copy(rootObject.scale);
    this.rootObject.quaternion.copy(rootObject.quaternion);
    this.rootObject.updateMatrix();
    this.remove(this.children[0]);
    this.isCleanedUp = true;
  }
}
