import { Object3D, SpriteMaterial, Sprite, LinearMipMapLinearFilter } from 'three';
import { ASSETS_PATH } from '../../../../data/sitesettings';
import anime from 'animejs/lib/anime.es';

export default class Button extends Object3D {
  constructor(opts) {
    super();
    this.plusMaterial = opts.plusMaterial;
    this.opacity = 0;
    const isSmall = window.innerWidth < 1025;

    const hoverTex = window.textureManager.get(ASSETS_PATH + 'images/cube/plus-hover.png');
    hoverTex.minfilter = LinearMipMapLinearFilter;
    hoverTex.needsUpdate = true;

    const hoverMaterial = new SpriteMaterial({
      map: hoverTex,
      color: 0xffffff,
      opacity: 0,
      depthWrite: false
    });

    const titleTex = window.textureManager.get(opts.titlePath);
    titleTex.minfilter = LinearMipMapLinearFilter;
    titleTex.needsUpdate = true;
    this.titleMaterial = new SpriteMaterial({ map: titleTex, opacity: 0, depthWrite: false });
    this.bgSprite = new Sprite(new SpriteMaterial({ color: 0xff0000, opacity: 0, depthWrite: false }));
    this.bgSprite.scale.set(1, 1, 1);

    this.title = new Sprite(this.titleMaterial);
    this.hover = new Sprite(hoverMaterial);
    this.plus = new Sprite(this.plusMaterial);
    this.plus.scale.set(100, 100, 1).multiplyScalar(0.004 * (isSmall ? 2 : 1.5));
    this.bgSprite.scale.set(100, 100, 1).multiplyScalar(0.004 * (isSmall ? 5 : 10));
    this.hover.scale.set(128, 128, 1).multiplyScalar(0.004 * (isSmall ? 2 : 1.5));
    this.title.scale.set(titleTex.image.width / 2, titleTex.image.height / 2, 1).multiplyScalar(0.013 * (isSmall ? 2 : 1.5));
    this.plus.scale.z = this.hover.scale.z = this.title.scale.z = 1;
    // this.title.position.y = 0.3;

    this.add(this.title);
    this.add(this.hover);
    this.add(this.plus);
    this.add(this.bgSprite);
  }

  over() {
    anime.remove(this.hover.material);
    anime({
      targets: this.hover.material,
      opacity: 1,
      duration: 250,
      easing: 'easeOutSine'
    });
  }

  out() {
    anime.remove(this.hover.material);
    anime({
      targets: this.hover.material,
      opacity: 0,
      duration: 250,
      easing: 'easeOutSine'
    });
  }

  update() {
    this.title.material.opacity = this.plus.material.opacity = this.opacity;
  }
}
