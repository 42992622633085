import React from 'react';
// import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es';
import './style.scss';

import checkProps from '../../util/check-props';

export default class ScrollComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.ctn = React.createRef();
  }

  componentDidMount() {
    this.ctn.current.style.overflow = 'hidden';
    this.ctn.current.firstChild.style.width = '100vw';
    anime({
      targets: this.ctn.current,
      width: 0,
      duration: 0
    });

    this.scrollList = Array.from(this.ctn.current.querySelectorAll('.scroll-appear'));
    this.scrollList.forEach((s, i) => {
      const top = s.getBoundingClientRect().top;
      if (top > window.innerHeight) {
        anime({
          targets: s,
          duration: 0,
          opacity: 0,
          // rotate: -3,
          translateY: -50
        });
      } else {
        s.classList.add('starting');
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScroll);
  }

  componentWillReceiveProps(nextProps) {}

  animateIn(delay = 0) {
    // this.ctn.current.style.left = '100vw';
    return anime({
      targets: this.ctn.current,
      // width: '100vw',
      width: '100%',
      // left: '0',
      delay,
      duration: 1650,
      easing: 'easeOutExpo'
    }).finished.then(() => {
      this.ctn.current.style.overflow = 'visible';
      this.ctn.current.firstChild.style.width = 'auto';
      this.ctn.current.firstChild.style.right = 'auto';
      window.addEventListener('scroll', this.checkScroll);
      this.checkScroll();
    });
  }

  checkScroll = () => {
    const appear = [];
    this.scrollList.forEach(d => {
      if (!d.classList.contains('show') && !d.classList.contains('starting')) {
        const r = d.getBoundingClientRect();
        if (r.top > 0 && r.top < this.props.viewPortHeight) {
          appear.push(d);
          d.classList.add('show');
        }
      }
    });
    if (appear.length > 0) {
      this.scrollAnimeIn(appear);
    }
  };

  scrollAnimeIn(targets) {
    anime({
      targets,
      duration: 500,
      // rotate: [-3, 0],
      opacity: [0, 1],
      translateY: [-50, 0],
      delay: anime.stagger(200, { start: 200 }),
      easing: 'easeOutSine'
    });
  }
}

ScrollComponent.propTypes = checkProps({});

ScrollComponent.defaultProps = {};
