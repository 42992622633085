import React from 'react';
// import { withRouter } from 'react-router-dom';
import { GA_TRACKING_ID } from '../../data/sitesettings';

class GoogleAnalytics extends React.Component {
  constructor() {
    super();
    this.state = { location: '' };
  }

  updateAnalytics = location => {
    if (this.state.location !== location.pathname) {
      const gtag = window.gtag;

      gtag('config', GA_TRACKING_ID, {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname
      });

      this.setState({ location: location.pathname });
    }
  };

  render() {
    return null;
  }
}

export default GoogleAnalytics;
