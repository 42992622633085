import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';
import anime from 'animejs/lib/anime.es';

import checkProps from '../../util/check-props';
import WebglRenderer from './webgl/WebglRenderer';
import { Vector2 } from 'three';
import { changeFrame } from '../../store/modules/home';

//const Stats = require('stats-js');

class WebglBackground extends React.PureComponent {
  constructor(props) {
    super(props);
    this.oglCanvas = React.createRef();
    this.canvasOpacity = 1;
    this.state = { canvasOpacity: 1 };
  }

  componentDidMount() {
    this.renderer = new WebglRenderer(this.oglCanvas.current);
    this.renderer.on('click-button', this.clickButton);
    //this.stats = new Stats();
    //document.body.appendChild(this.stats.dom);
    this.mousePos = new Vector2(-100, -100);
    this.renderer.renderer.domElement.addEventListener('mousedown', () => {
      this.renderer.isDragging = false;
    });
    this.renderer.renderer.domElement.addEventListener('mousemove', this.mouseMove);
    this.renderer.renderer.domElement.addEventListener('touchstart', event => {
      this.renderer.isDragging = false;
      this.touchMove(event);
      this.renderer.checkClickBtn(event);
    });
    this.renderer.renderer.domElement.addEventListener('touchmove', event => {
      this.renderer.isDragging = true;
      this.touchMove(event);
    });
    this.renderer.resize(this.props.viewPortWidth, this.props.viewPortHeight);
  }

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate(prevProps) {
    if (prevProps.currFrame !== this.props.currFrame) {
      this.renderer.goTo(this.props.currFrame);

      if(this.props.viewPortWidth < 769) {
        const canvasOpacity = this.props.currFrame === 0 ? 1 : 0.2;
        anime({
          targets: this,
          canvasOpacity: canvasOpacity,
          duration: 1500,
          delay: 500,
          easing: 'easeOutSine',
          update: anim => this.setState({ canvasOpacity: this.canvasOpacity })
        });
      }
    }
    if (
      prevProps.viewPortWidth !== this.props.viewPortWidth ||
      prevProps.viewPortHeight !== this.props.viewPortHeight
    ) {
      this.renderer.resize(this.props.viewPortWidth, this.props.viewPortHeight);
    }
  }

  mouseMove = event => {
    this.renderer.isDragging = true;
    this.mousePos.set(
      (event.clientX / this.props.viewPortWidth) * 2 - 1,
      -1 * ((event.clientY / this.props.viewPortHeight) * 2 - 1)
    );
  };

  touchMove = event => {
    if (event.touches.length === 1) {
      var rect = this.renderer.renderer.domElement.getBoundingClientRect();
      this.mousePos.set(
        (event.touches[0].clientX / this.props.viewPortWidth) * 2 - 1,
        -((event.touches[0].clientY - rect.top) / rect.height) * 2 + 1
      );
    }
  };

  render3D = isAnimating => {
    this.renderer.mousePos = this.mousePos;
    this.renderer.render(isAnimating);
  };

  clickButton = id => {
    this.props.changeFrame(id + 1);
  };

  render() {
    return (
      <div style={{opacity: this.state.canvasOpacity}} className="WebglBackground">
        <canvas ref={this.oglCanvas} />
      </div>
    );
  }
}

WebglBackground.propTypes = checkProps({
  changeFrame: PropTypes.func,
  viewPortWidth: PropTypes.number,
  viewPortHeight: PropTypes.number,
  currFrame: PropTypes.number
});

WebglBackground.defaultProps = {};

const mapStateToProps = state => {
  return {
    viewPortWidth: state.resizing.width,
    viewPortHeight: state.resizing.height,
    currFrame: state.home.currFrame
  };
};

const mapDispatchToProps = dispatch => ({
  changeFrame: frame => dispatch(changeFrame(frame))
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(WebglBackground);
