import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './style.scss';

import checkProps from '../../util/check-props';

import content from '../../data/case-studies-index.json';
import projects from '../../data/case-studies.json';
import ScrollComponent from '../../components/ScrollComponent';
import ContactBlock from '../../components/ContactBlock';
import PageMeta from '../../components/PageMeta';

class CaseStudies extends ScrollComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.name = 'case-studies';
    this.ctn = React.createRef();
  }

  componentDidMount() {
    this.props.onSetCurrentComponent(this);
    super.componentDidMount();
  }

  componentWillReceiveProps(nextProps) {}

  openProject = slug => {
    this.props.history.push('/case-studies/' + slug);
  };

  render() {
    return (
      <div className="CaseStudies" ref={this.ctn}>
        <PageMeta title={content.title} />
        <div className="regular-page pt-sm">
          <div className="container">
            <div className="crumbs content-wrapper hundred-percent height-auto white pb-5">
              &nbsp;
            </div>
          </div>
          <div className="container case-studies">
            <div className="row">
              {projects['case-studies'].map((c, i) => (
                <a href={'/case-studies/' + c.slug} className="item text-white col-12 col-md-6 col-lg-4 pb-5 scroll-appear" key={i} onClick={(e) => {
                  e.preventDefault();
                  this.openProject(c.slug)
                  }}>
                  <div className="inner">
                    <div className="img" style={{ backgroundImage: `url(${c.mainImage})` }} />
                    <div className="ctn text-center">
                      <h2>{c.title}</h2>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
          <ContactBlock />
        </div>
      </div>
    );
  }
}

CaseStudies.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func,
  dispatch: PropTypes.func,
  viewPortHeight: PropTypes.number
});

CaseStudies.defaultProps = {};

const mapStateToProps = state => {
  return {
    viewPortHeight: state.resizing.height
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(CaseStudies));
