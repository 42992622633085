import React from 'react';
import './style.scss';

import checkProps from '../../util/check-props';
import anime from 'animejs/lib/anime.es';

export default class ContactForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      phone: ''
    };

    this.updateField = this.updateField.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.alert = React.createRef();
  }

  updateField(field, value) {
    this.setState({ [field]: value });
  }
  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  sendMessage(templateId, variables) {
    window.emailjs.send(
      'godin_gmail', templateId,
      variables
      ).then(res => {
        // console.log('Email successfully sent!')

        anime({
          targets: this.alert.current,
          opacity: 1,
          delay: 0,
          duration: 350,
          easing: 'easeOutSine'
        });

        var alert = this.alert.current;

        setTimeout(function() {
          anime({
            targets: alert,
            opacity: 0,
            delay: 0,
            duration: 350,
            easing: 'easeOutSine'
          });
        }, 10000);
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err));
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.phone === '') {
      const templateId = 'template_OT6Ezh2X';
      this.sendMessage(templateId, {message_html: this.state.message, from_name: this.state.name, reply_to: this.state.email});
      // console.log(this.state.message);
    }
  }

  render() {
    return (
      <div className="row">
        <form className="ContactForm col-12 col-md-8" onSubmit={this.handleSubmit}>
          <input type="text" name="name" className="form-control" placeholder="Name" onChange={(event) => this.updateField('name', event.target.value)} value={this.state.name} required />
          <input type="email" name="email" className="form-control" placeholder="Email" onChange={(event) => this.updateField('email', event.target.value)} value={this.state.email} required />
          <textarea className="form-control" placeholder="Tell us a bit about how we can help you..." onChange={(event) => this.updateField('message', event.target.value)} value={this.state.message} required></textarea>
          <input type="text" name="ph0n3" className="form-control phone" placeholder="Phone" onChange={(event) => this.updateField('phone', event.target.value)} value={this.state.phone} />
          <div className="form-footer mt-4">
            <button name="submit" type="submit" className="BasicButton btn-lg black navy scroll-appear">send message</button>
            <div ref={this.alert} className="alert success">Your message was sent successfully.</div>
          </div>
        </form>
      </div>
    );
  }
}

ContactForm.propTypes = checkProps({});

ContactForm.defaultProps = {};
