import { Quaternion, Euler, Vector2 } from 'three';
import lerp from 'lerp';

export class MeshRotation {
  constructor(domElement, object) {
    this.enabled = true;
    this.domElement = domElement;
    this.object = object;

    this.mouseX = 0;
    this.mouseY = 0;

    this.windowHalfX = window.innerWidth / 2;
    this.windowHalfY = window.innerHeight / 2;

    this.prevMousePos = new Vector2();

    this.rotQuaternion = new Quaternion();
    this.rotQuaternion0 = new Quaternion();
    this.rotQuaternion1 = new Quaternion();
    this.mouseVec = new Euler();
    this.domElement.addEventListener('mousedown', this.mouseDown, false);
    this.domElement.addEventListener('touchstart', this.touchStart, false);
    this.domElement.addEventListener('touchmove', this.touchMove, false);
  }

  resize(width, height) {
    this.width = width;
    this.height = height;

    this.windowHalfX = this.width / 2;
    this.windowHalfY = this.height / 2;
  }

  toggleEnable(b) {
    if (b) {
      this.domElement.addEventListener('mousedown', this.mouseDown, false);
      this.domElement.addEventListener('touchstart', this.touchStart, false);
      this.domElement.addEventListener('touchmove', this.touchMove, false);
    } else {
      this.domElement.removeEventListener('mousedown', this.mouseDown, false);
      this.domElement.removeEventListener('touchstart', this.touchStart, false);
      this.domElement.removeEventListener('touchmove', this.touchMove, false);
    }

    this.enabled = b;
  }

  mouseDown = event => {
    event.preventDefault();

    this.domElement.addEventListener('mousemove', this.mouseMove, false);
    this.domElement.addEventListener('mouseup', this.mouseUp, false);
    this.domElement.addEventListener('mouseout', this.mouseUp, false);
    this.mouseX = event.clientX;
    this.mouseY = event.clientY;
    this.prevMousePos.set(this.mouseX, this.mouseY);
  };

  mouseMove = event => {
    this.mouseVec.set(
      (event.clientY - this.prevMousePos.y) * (Math.PI / 180) * 0.01,
      (event.clientX - this.prevMousePos.x) * (Math.PI / 180) * 0.01,
      0,
      'XYZ'
    );

    this.prevMousePos.set(this.mouseX, this.mouseY);
  };

  mouseUp = event => {
    this.domElement.removeEventListener('mousemove', this.mouseMove, false);
    this.domElement.removeEventListener('mouseup', this.mouseUp, false);
    this.domElement.removeEventListener('mouseout', this.mouseUp, false);
  };

  touchStart = event => {
    if (event.touches.length === 1) {
      event.preventDefault();
      this.mouseX = event.touches[0].clientX;
      this.mouseY = event.touches[0].clientY;
      this.prevMousePos.set(this.mouseX, this.mouseY);
    }
  };

  touchMove = event => {
    if (event.touches.length === 1) {
      this.mouseVec.set(
        (event.touches[0].clientY - this.prevMousePos.y) * (Math.PI / 180) * 0.01,
        (event.touches[0].clientX - this.prevMousePos.x) * (Math.PI / 180) * 0.01,
        0,
        'XYZ'
      );

      this.prevMousePos.set(this.mouseX, this.mouseY);
    }
  };

  update = () => {
    if (this.enabled) {
      this.rotQuaternion.setFromEuler(this.mouseVec);
      // Quaternion.slerp(this.rotQuaternion0, this.rotQuaternion, this.rotQuaternion1, 0.07);
      this.object.quaternion.multiplyQuaternions(this.rotQuaternion, this.object.quaternion);

      this.mouseVec.x = lerp(this.mouseVec.x, 0, 0.1);
      this.mouseVec.y = lerp(this.mouseVec.y, 0, 0.1);
    }
  };
}
