import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactMarkDown from 'react-markdown';
import './style.scss';

import checkProps from '../../util/check-props';
import BasicButton from '../../components/BasicButton';

import content from '../../data/services.json';
// import contentCaseStudies from '../../data/case-studies.json';
import ScrollComponent from '../../components/ScrollComponent';
import ContactBlock from '../../components/ContactBlock';
import PageMeta from '../../components/PageMeta';

class Services extends ScrollComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.onSetCurrentComponent(this);
    super.componentDidMount();
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <div className="Services" ref={this.ctn}>
        <PageMeta title={content.title} />
        <div className="regular-page pt-sm">
          <div className="container">
            <div className="crumbs content-wrapper hundred-percent height-auto white pb-5">
              &nbsp;
            </div>
          </div>
          <section className="content-section container pt-0">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <h1 className="mw-50">{content.title}</h1>
              </div>
              <div className="col-12 col-md-7 col-lg-8">
                <p>{content.overview}</p>
              </div>
            </div>
          </section>
          {content.serviceList.map((service, i) => (
            <div key={i} className="services--item" style={{ backgroundColor: service.color }}>
              <div className="container" key={i}>
                <div className="row mb-5-x pb-5-x">
                  <div className="service-content col-12 col-md-5 pr-lg-5 py-5">
                    <div className="text pb-5 mb-4">
                      <h2 className="scroll-appear">{service.title}</h2>
                      <p className="scroll-appear">
                        <ReactMarkDown source={service.content} />
                      </p>
                      <BasicButton
                        url={'/case-studies/' + service.slug}
                        onClick={(e) => { this.props.history.push('/case-studies/' + service.slug); e.preventDefault(); }}
                        className="btn-lg scroll-appear"
                      >
                        view case study
                      </BasicButton>
                    </div>
                  </div>
                  <div className="service-image col-12 col-md-7 text-center">
                    <div className="inner h-100 w-100 px-5 align-items-center align-items-lg-end d-flex justify-content-center">
                      <img className="scroll-appear services--platform" src={service.image} alt={service.title} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <ContactBlock />
        </div>
      </div>
    );
  }
}

Services.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func,
  dispatch: PropTypes.func,
  viewPortHeight: PropTypes.number
});

Services.defaultProps = {};

const mapStateToProps = state => {
  return {
    viewPortHeight: state.resizing.height
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(Services));
