import { RESIZE } from '../keys';

const size = {
  width: 0,
  height: 0
};

const defaultState = {
  size
};

export default function resizingReducer(state = defaultState, action) {
  switch (action.type) {
    case RESIZE:
      return { ...state, width: action.width, height: action.height };
    default:
      return state;
  }
}

export function resize(width, height) {
  return { type: RESIZE, width, height };
}
