import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import anime from 'animejs/lib/anime.es';
import ReactHtmlParser from 'react-html-parser';
import { withRouter, NavLink } from 'react-router-dom';
import './style.scss';

import checkProps from '../../util/check-props';

import content from '../../data/case-studies.json';
import ScrollComponent from '../../components/ScrollComponent';
import ContactBlock from '../../components/ContactBlock';
import PageMeta from '../../components/PageMeta';

var showdown = require('showdown');

class CaseStudy extends ScrollComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.ctn = React.createRef();
    this.mk = new showdown.Converter();
  }

  componentWillMount() {}

  getCaseStudyByID(slug) {
    return content['case-studies'].filter(c => c.slug === slug)[0];
  }

  componentDidMount() {
    this.props.onSetCurrentComponent(this);
    super.componentDidMount();
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <div className="CaseStudy" ref={this.ctn}>
        <PageMeta title={this.props.content.title} />
        <div className="regular-page pt-sm">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="crumbs content-wrapper hundred-percent height-auto white">
                  <div className="mw-100">
                    <p className="font-weight-light"><NavLink to="/case-studies" className="link-nounderline text-muted">Case studies</NavLink> / <span className="text-dark">{this.props.content.title}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="content-section container pt-5 pb-0">
            <div className="content-wrapper full-size height-auto white">
              <div>
                <h1 className="scroll-appear">
                  {this.props.content.title}
                </h1>
                <h2 className="h4 scroll-appear content__sub-head mb-0">The challenge</h2>
                <p className="scroll-appear mb-0 mw-md-70">{this.props.content.overview}</p>
              </div>
            </div>
          </section>
          <section className="content-section container-wrap font-weight-light pb-0">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center font-0">
                  <img className="scroll-appear mw-100" src={this.props.content.heroImage} alt={this.props.content.title} /><br/>
                </div>
              </div>
            </div>
            <div className="container scroll-appear">
              <div className="row">
                <div className="col-12 text-center text-white">
                  <div className="mh-500 inner-section" style={{ backgroundImage: `url(${this.props.content.solutionBackgroundImage})`, backgroundSize: `cover` }}>
                    <div className="row">
                      <div className="col-md-8 offset-md-2 ie-flex-fix">
                        <h2 className="h4 scroll-appear mb-0">The solution</h2>
                        <div className="scroll-appear px-4">
                          {ReactHtmlParser(this.mk.makeHtml(this.props.content.impactContent))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="content-section container">
            <div className="content-wrapper white height-auto row">
              <div className="col-12 mb-5 mb-lg-0 col-lg-5">
                <h2 className="h4 scroll-appear content__sub-head mb-0">The results</h2>
                {ReactHtmlParser(this.mk.makeHtml(this.props.content.resultsOverview))}
              </div>
              <div className="text-center col-12 col-lg-7">
                <div className="d-flex justify-content-end" {...(this.props.content.resultsImages.length > 1 ? {count: this.props.content.resultsImages.length} : {}) }>
                  {this.props.content.resultsImages.map((img, i) => (
                    <div key={i} className="col-6-x mt-5 text-center scroll-appear content__image">
                      <img src={img.image} className="mw-100" alt={img.alt} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <section className="content-section container scroll-appear prev-next-nav pt-0">
            <div className="row text-muted">
              <div className="col-6">
                {this.props.id > 0 && (
                  'Previous / '
                )}
                {this.props.id > 0 && (
                  <NavLink
                    to={'/case-studies/' + content['case-studies'][this.props.id - 1].slug}
                    className="link-nounderline font-weight-light text-dark"
                  >
                    {content['case-studies'][this.props.id - 1].title}
                  </NavLink>
                )}
              </div>
              <div className="col-6 text-right">
                {this.props.id < content['case-studies'].length - 1 && (
                  'Next / '
                )}
                {this.props.id < content['case-studies'].length - 1 && (
                  <NavLink
                    to={'/case-studies/' + content['case-studies'][this.props.id + 1].slug}
                    className="link-nounderline font-weight-light text-dark"
                  >
                    {content['case-studies'][this.props.id + 1].title}
                  </NavLink>
                )}
              </div>
            </div>
          </section>
          <ContactBlock />
        </div>
      </div>
    );
  }
}

CaseStudy.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func,
  dispatch: PropTypes.func,
  viewPortHeight: PropTypes.number,
  content: PropTypes.object,
  id: PropTypes.number
});

CaseStudy.defaultProps = {
  content: {}
};

const mapStateToProps = state => {
  return {
    viewPortHeight: state.resizing.height
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(CaseStudy));
