import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import './style.scss';

import checkProps from '../../util/check-props';
import MainMenu from '../MainMenu';
import { toggleMenu } from '../../store/modules/menu';
import MenuClose from '../SvgComponents/MenuClose/MenuClose';

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { prevPath: '' };
    this.menuBtn = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.headerAffixCheck);
  }

  headerAffixCheck = () => {
    const nav = document.querySelector(".Header");
    const affixClass = "affix";
    const currentScroll = window.pageYOffset;

    if (currentScroll <= 0) {
      nav.classList.remove(affixClass);
      return;
    } else {
      nav.classList.add(affixClass);

      setTimeout(function() {
        if (window.pageYOffset <= 0) {
          nav.classList.remove(affixClass);
          return;
        }
      }, 200);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.setState({ prevPath: this.props.location });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.menuIsOpen !== this.props.menuIsOpen) {
      if (this.props.menuIsOpen) {
        this.menuBtn.current.animateToClose();
      } else {
        this.menuBtn.current.animateToMenu();
      }
    }
  }

  render() {
    return (
      <div className="Header">
        <div className="container">
          <div className="row row--header-nav">
              <div className="col-6">
                <div className="brand">
                  <NavLink
                    onClick={event => {
                      // if (this.props.location.pathname === '/'){
                      //   event.preventDefault();
                      // }
                    }}
                    to="/"
                    className="p-relative"
                  >
                    <img className="default" src="/assets/images/godin-logo.png" alt="Logo Godin" />
                    <img className="black" src="/assets/images/godin-logo-dark-blue.png" alt="Logo Godin" />
                  </NavLink>
                </div>
              </div>
              <div className="col-6 buttons text-right d-flex justify-content-end">
                <button
                  className="toggle-menu"
                  onClick={this.props.toggleMenu}
                  onMouseOver={() => this.menuBtn.current.over()}
                  onMouseOut={() => this.menuBtn.current.out()}
                >
                  <MenuClose ref={this.menuBtn} />
                </button>
              </div>
              <MainMenu />
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = checkProps({
  toggleMenu: PropTypes.func,
  menuIsOpen: PropTypes.bool
});

Header.defaultProps = {};

const mapDispatchToProps = dispatch => ({
  toggleMenu: () => dispatch(toggleMenu())
});

const mapStateToProps = state => {
  return {
    menuIsOpen: state.menu.menuIsOpen
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header));
