import { Matrix4, Vector3 } from 'three';

export const ASSETS_PATH = '/assets/';
export const CUBE_PATH = 'objects/CUBE_10.glb';
export const MOBILE_CUBE_PATH = 'objects/CUBE.glb';
export const HOME_MARGIN = 200;

export const CUBE_QUATS = [
  new Matrix4().makeRotationZ(Math.PI),
  new Matrix4().makeRotationZ(0),
  new Matrix4().makeRotationZ(-Math.PI * 0.5),
  new Matrix4().makeRotationX(-Math.PI * 0.5),
  new Matrix4().makeRotationZ(Math.PI),
  new Matrix4().makeRotationX(Math.PI * 0.5),
  new Matrix4().makeRotationZ(Math.PI * 0.5)
];

export const BTNS_DIRS = [
  new Vector3(0, -1, 0),
  new Vector3(1, 0, 0),
  new Vector3(0, 0, 1),
  new Vector3(0, 1, 0),
  new Vector3(0, 0, -1),
  new Vector3(-1, 0, 0)
];

export const FACE_ORIENTATION = [0, 0, Math.PI, Math.PI, Math.PI / 2, Math.PI, Math.PI / 2];

export const NAV_CIRCLE_RADIUS = 350;

export const GA_TRACKING_ID = 'UA-147618976-1';
