import EventEmitter from 'events';
import {
  WebGLRenderer,
  PlaneGeometry,
  PerspectiveCamera,
  Quaternion,
  Vector3,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Scene,
  //DirectionalLight,
  AmbientLight,
  // BoxBufferGeometry,
  Raycaster
} from 'three';
import anime from 'animejs/lib/anime.es';
// import smoothstep from 'smoothstep';
import lerp from 'lerp';
import { CUBE_QUATS, ASSETS_PATH, FACE_ORIENTATION } from '../../../data/sitesettings';
import CubeLoader from './objects/CubeLoader';
import TextureManager from '../../../util/TextureManager';
import Easing from 'easing-functions';
// import OrbitControls from 'three-orbitcontrols';
// import Background from './objects/Background';
import Buttons from './objects/Buttons';
import { MeshRotation } from '../../../util/threeMeshRotation';

const BO = Easing.Back.Out;

export default class WebglRenderer extends EventEmitter {
  constructor(canvas) {
    super();
    this.renderer = new WebGLRenderer({
      canvas,
      antialias: false,
      stencil: false,
      premultipliedAlpha: false
    });

    this.renderer.setClearColor(0x81b0cd);
    this.renderer.autoClear = true;

    this.currFrame = this.frameToGo = 0;

    this.renderer.gammaFactor = 2.2;
    this.renderer.gammaOutput = true;

    this.isSmall = window.innerWidth <= 1024;

    this.cameraOrientation = 0;

    // this.gl = this.renderer.gl;
    window.textureManager = new TextureManager(this.gl);
    this.scene = new Scene();
    this.centerScene = new Vector3(0, this.isSmall ? 5 : 0, 0);

    this.perc = this.percNoLerp = this.globPerc = this.introPerc = 0;

    // this.gl.clearColor(0, 0, 0, 0);
    this.camera = new PerspectiveCamera(50, Window.innerWidth / window.innerHeight, 1, 2000);
    // this.bgCamera = new PerspectiveCamera(50, Window.innerWidth / window.innerHeight, 1, 100);
    this.currQuat = new Quaternion();
    this.nextQuat = new Quaternion();
    this.camQuat = new Quaternion();
    this.positionCam = new Vector3();
    this.lookAtCam = new Vector3();
    this.radiusCam = 3;
    this.rotateAdd = 0.2;
    this.scene.position.copy(this.centerScene);
    this.angleCamera = -Math.PI * 0.5;
    this.time = Date.now();
    this.rotateAngle = 0;
    this.rotateAngleLerp = 0;

    this.firstStep = 1;

    this.rotateSceneWrapper = new Object3D();
    this.objectsWrapper = new Object3D();
    this.insideWrapper = new Object3D();
    this.rotateMouse = new Object3D();
    this.rotateEffect = new Object3D();
    this.cubeLoader = new CubeLoader();
    this.buttons = new Buttons();
    this.cubeLoader.position.set(0, 0, 0);

    this.scene.add(this.rotateMouse);
    this.rotateMouse.add(this.insideWrapper);
    this.insideWrapper.add(this.rotateEffect);
    this.rotateEffect.add(this.rotateSceneWrapper);
    this.rotateSceneWrapper.add(this.objectsWrapper);
    this.objectsWrapper.add(this.cubeLoader);
    this.objectsWrapper.add(this.buttons);
    //this.directionalLight = new DirectionalLight(0xffffff, 0.5);
    this.ambientLight = new AmbientLight(0xffffff, 1);
    //this.directionalLight.position.set(0.3, 0.3, -0.3);
    //this.scene.add(this.directionalLight);
    this.scene.add(this.ambientLight);
    this.objectsWrapper.rotation.set(0, 0, Math.PI);

    this.camera.position.set(-1.5, 4, this.isSmall ? -8 : -6);

    this.quatMouse = new Quaternion();

    this.raycaster = new Raycaster();
    this.cubeLoader.scale.setScalar(0.013);

    this.meshRotation = new MeshRotation(this.renderer.domElement, this.rotateSceneWrapper);

    this.setShadow();
    // this.background = this.setBackground();
    // this.bgScene = new Scene();
    // this.bgScene.add(this.background);

    this.renderer.domElement.addEventListener('click', this.checkClickBtn);

    this.goTo(0);
  }

  setShadow() {
    const texture = window.textureManager.get(ASSETS_PATH + 'images/cube-shadow.png');

    const planeGeom = new PlaneGeometry(1, 1);
    const shadowMat = new MeshBasicMaterial({
      map: texture, 
      color: 0x111,
      transparent: true,
      depthWrite: false,
      opacity: 0.25
    });
    this.shadowMesh = new Mesh(planeGeom, shadowMat);
    this.shadowMesh.rotation.x = -Math.PI / 2;
    this.shadowMesh.position.y = -5;
    this.shadowMesh.scale.setScalar(10);
    this.rotateEffect.add(this.shadowMesh);
  }

  /* setBackground() {
    const bg = new Background({ camera: this.camera });
    return bg;
  } */

  resize(width, height) {
    this.renderer.setPixelRatio(2);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.meshRotation.resize(width, height);
    // this.bgCamera.aspect = width / height;
    // this.bgCamera.updateProjectionMatrix();
    // this.background.resize(width / height, this.bgCamera.position.z, height);
    this.renderer.setSize(width, height);
  }

  /* scroll = perc => {
    this.perc = perc;
  }; */

  rotateSceneWithScroll() {
    this.currQuat.slerp(this.nextQuat, this.perc);
    this.rotateSceneWrapper.quaternion.slerp(this.currQuat, 0.4);
  }

  moveCameraGlobal(firstStep, dt) {
    this.rotateAngleLerp += this.rotateAdd;
    this.rotateAngle += (this.rotateAngleLerp - this.rotateAngle) * Math.min(1, dt);
    const invIntro = BO(1 - this.introPerc);
    this.angleCamera =
      Math.PI * 0.25 + firstStep * ((this.rotateAngle * 0.025) % (Math.PI * 2)) + this.cameraOrientation;
    this.camQuat.set(0, 0, 0, 1).setFromAxisAngle(new Vector3(0, 1, 0), this.angleCamera);
    this.rotateEffect.quaternion.slerp(this.camQuat, this.perc);
    this.rotateMouse.position.set(
      this.isSmall ? 0 + 2 * firstStep : -3 + 3 * firstStep,
      invIntro * -60 + (this.isSmall ? -10 : -3) + (this.isSmall ? -4 : -4) * firstStep,
      firstStep * (this.isSmall ? 10 : 10) + 1
    );
    this.lookAtCam.set(0, 0.5, 0);
    this.camera.lookAt(this.lookAtCam);
  }

  checkClickBtn = event => {
    if(this.isDragging) return;
    this.raycaster.setFromCamera(this.mousePos, this.camera);
    const intersects = this.raycaster.intersectObjects(this.buttons.allBtnsSprite);
    if (intersects.length > 0) {
      this.emit('click-button', intersects[0].object.parent.btID);
    }
  };

  goTo(id) {
    this.currFrame = id;
    this.meshRotation.enabled = id === 0;
    this.currQuat.copy(this.rotateSceneWrapper.quaternion);
    this.nextQuat.setFromRotationMatrix(CUBE_QUATS[id]);

    this.perc = 0;
    if (id === 0) {
      this.buttons.animateIn();
    } else {
      this.buttons.animateOut();
    }
    anime({
      targets: this,
      perc: 1,
      firstStep: id === 0 ? 1 : 0,
      cameraOrientation: FACE_ORIENTATION[id],
      easing: 'easeInOutSine',
      duration: 2000,
      update: () => this.rotateSceneWithScroll()
    });
  }

  render = isAnimating => {
    //this.frameToGo = lerp(this.frameToGo, this.currFrame, 0.1);
    const dt = Math.min((Date.now() - this.time) * 0.005, 1.5);
 
    this.time = Date.now();

    this.introPerc = lerp(this.introPerc, 1, Math.min(dt * 0.4, 1));

    //Cleaning up cube
    if (!this.cubeLoader.isCleanedUp) {
      this.cubeLoader.cleanUp();
    }

    if (!this.isSmall) {
      this.rotateMouse.rotation.set(
        (1 - this.firstStep) * this.mousePos.y * Math.PI * 0.01,
        (1 - this.firstStep) * -this.mousePos.x * Math.PI * 0.01,
        0
      );
    }

    this.moveCameraGlobal(this.firstStep, dt);
    this.meshRotation.update();
    this.cubeLoader.cameraPosition = this.camera.position;
    this.raycaster.setFromCamera(this.mousePos, this.camera);
    const intersects = this.raycaster.intersectObjects(this.buttons.allBtnsSprite);
    this.buttons.update();
    if (!this.isSmall) {
      if (intersects.length > 0) {
        this.buttons.over(intersects[0].object.parent.btID);
        this.rotateAdd += (0 - this.rotateAdd) * dt;
      } else {
        this.buttons.out();
        this.rotateAdd += (0.2 - this.rotateAdd) * dt;
      }
    }
    this.renderer.render(this.scene, this.camera);
  };
}
