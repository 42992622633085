import { TOGGLE_MENU } from '../keys';

const defaultState = {
  menuIsOpen: false
};

export default function homeReducer(state = defaultState, action) {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        menuIsOpen: !state.menuIsOpen
      };
    default:
      return state;
  }
}

export function toggleMenu() {
  return { type: TOGGLE_MENU };
}
