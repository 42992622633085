import { Object3D, SpriteMaterial, LinearMipMapLinearFilter } from 'three';
import Button from './Button';
import { ASSETS_PATH, BTNS_DIRS } from '../../../../data/sitesettings';
import anime from 'animejs/lib/anime.es';
import homeContent from '../../../../data/home.json';

export default class Buttons extends Object3D {
  constructor(opts = {}) {
    super();
    this.isSmall = window.innerWidth < 1025;
    this.currentID = -1;
    this.radius = 7;
    const plusTex = window.textureManager.get(ASSETS_PATH + 'images/cube/plus.png');
    plusTex.minfilter = LinearMipMapLinearFilter;
    plusTex.needsUpdate = true;
    const plusMaterial = new SpriteMaterial({
      map: plusTex,
      color: 0xffffff
    });

    homeContent.activities.forEach((c, i) => {
      if (i !== 0) {
        const btn = new Button({
          plusMaterial,
          titlePath: c.cubeButton
        });

        btn.btID = i - 1;

        btn.visible = c.toggleCubeButton;

        this.add(btn);
      }
    });

    this.allBtnsSprite = [];

    for (let i = 0; i < 6; i++) {
      this.allBtnsSprite.push(this.children[i].bgSprite);
    }
    this.animateIn();

    // if (this.isSmall) this.visible = false;
  }

  animateOut() {
    anime({
      targets: this.children,
      opacity: 0,
      duration: 500,
      easing: 'easeOutSine'
    });
    return anime({
      targets: this,
      radius: 8,
      duration: 500,
      easing: 'easeOutSine',
      complete: () => (this.visible = false)
    });
  }

  animateIn() {
    this.visible = true;
    anime({
      targets: this.children,
      opacity: 1,
      duration: 1000,
      easing: 'easeOutSine'
    });
    return anime({
      targets: this,
      radius: 6,
      duration: 2000,
      easing: 'easeOutExpo'
    });
  }

  over = id => {
    if (this.currentID === id) return;
    document.body.classList.add('webgl-over');
    for (let i = 0; i < this.children.length; i++) {
      this.children[i].out();
    }
    this.children[id].over();

    this.currentID = id;
  };

  out = () => {
    if (this.currentID === -1) return;
    this.currentID = -1;
    document.body.classList.remove('webgl-over');
    for (let i = 0; i < this.children.length; i++) {
      this.children[i].out();
    }
  };

  update() {
    for (let i = 0; i < this.children.length; i++) {
      this.children[i].position.copy(BTNS_DIRS[i]).multiplyScalar(this.radius);
      this.children[i].update();
    }
  }
}
