import { Texture, NearestFilter } from 'three';

export default class TextureManager {
  constructor() {
    this._texturePool = [];
  }

  get(url) {
    const tinArray = this._texturePool.filter(t => t.url === url)[0];
    if (tinArray) return tinArray;

    const info = window.preload[url];
    if (!info) return console.error('asset doesnt exist');
    const t = new Texture(info.asset);
    t.minFilter = NearestFilter;
    t.needsUpdate = true;
    this._texturePool.push(t);

    return t;
  }
}
