import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import './style.scss';

import checkProps from '../../util/check-props';

import contactContent from '../../data/contact.json';
import ScrollComponent from '../../components/ScrollComponent';
import ContactForm from '../../components/ContactForm';
import PageMeta from '../../components/PageMeta';

var showdown = require('showdown');

class Contact extends ScrollComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.name = 'contact';
    this.ctn = React.createRef();
    this.mk = new showdown.Converter();
  }

  componentDidMount() {
    this.props.onSetCurrentComponent(this);
    super.componentDidMount();
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <div className="Contact" ref={this.ctn}>
        <PageMeta title={contactContent.title} />
        <div className="regular-page pt-sm">
          <div className="container">
            <div className="crumbs content-wrapper hundred-percent height-auto white pb-5">
              &nbsp;
            </div>
          </div>
          <section className="content-section container pt-0">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <h1 className="mw-70">{contactContent.title}</h1>
              </div>
              <div className="col-12 col-md-7 col-lg-8 text-secondary">
                {ReactHtmlParser(this.mk.makeHtml(contactContent.overview))}
                <ContactForm />
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

Contact.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func,
  dispatch: PropTypes.func,
  viewPortHeight: PropTypes.number
});

Contact.defaultProps = {};

const mapStateToProps = state => {
  return {
    viewPortHeight: state.resizing.height
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(Contact));
