import React from 'react';
import anime from 'animejs/lib/anime.es';

export default class MenuClose extends React.PureComponent {
  constructor() {
    super();
    this.ctn = React.createRef();
  }

  componentDidMount() {
    this.maxX = 0;
    this.allCircles = Array.from(this.ctn.current.querySelectorAll('circle'));
    this.allCircleX = this.allCircles.map(c => {
      this.maxX = Math.max(this.maxX, c.getAttribute('cx'));
      return c.getAttribute('cx');
    });

    anime({
      targets: this.allCircles,
      duration: 0,
      cx: this.maxX
    });
  }

  animateToClose() {
    anime({
      targets: this.allCircles,
      duration: 500,
      cx: (el, i) => {
        return this.allCircleX[i];
      },
      easing: 'easeInOutQuart'
    });
  }

  animateToMenu() {
    anime({
      targets: this.allCircles,
      duration: 500,
      delay: 200,
      cx: this.maxX,
      easing: 'easeInOutQuart'
    });
  }

  over() {
    anime({
      targets: this.allCircles,
      duration: 200,
      delay: anime.stagger(30),
      r: 2,
      easing: 'easeOutSine'
    });
  }

  out() {
    anime.remove(this.allCircles);
    anime({
      targets: this.allCircles,
      duration: 200,
      r: 2.97,
      easing: 'easeInSine'
    });
  }

  render() {
    return (
      <svg viewBox="0 0 50.2 46.28">
        <defs />
        <title>{`menu`}</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1" ref={this.ctn}>
            <circle className="cls-1" cx={2.97} cy={2.97} r={2.97} />
            <circle className="cls-1" cx={2.97} cy={43.3} r={2.97} />
            <circle className="cls-1" cx={25.1} cy={23.14} r={2.97} />
            <circle className="cls-1" cx={47.23} cy={2.97} r={2.97} />
            <circle className="cls-1" cx={47.23} cy={43.3} r={2.97} />
          </g>
        </g>
      </svg>
    );
  }
}
